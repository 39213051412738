import React, { useEffect, useState } from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { IngredientsCompletionProvider, languageId, setupLanguage, themeId } from '../cooklangSupport';
import { getIngredients } from '../data';

const options: editor.IStandaloneEditorConstructionOptions = {
    wordWrap: "on"
};

function EditRecipe({ text, onTextChanged }: { text?: string; onTextChanged?: (value: string) => void; }) {
    const monaco = useMonaco();
    const [languageInit, setLanguageInit] = useState<boolean>(false);
    const [ingredients, setIngredients] = useState<string[]>([]);

    useEffect(() => {
        getIngredients().then(aisles => {
            let res: string[] = [];
            for (const aisle of aisles) {
                res.push(...aisle.ingredients);
            }
            console.log(res);
            setIngredients(res);
        });
    }, [setIngredients]);

    useEffect(() => {
        if (!monaco) return;

        setupLanguage(monaco);
        setLanguageInit(true);
    }, [monaco]);

    useEffect(() => {
        if (!monaco) return;

        const instance = monaco.languages.registerCompletionItemProvider(
            languageId,
            new IngredientsCompletionProvider(ingredients)
        );

        return () => instance.dispose()
    }, [monaco, ingredients]);

    if (!languageInit) {
        return <div>Loading...</div>
    }

    return (<Editor
        language={languageId}
        theme={themeId}
        defaultValue={text}
        value={text}
        options={options}
        onChange={(e) => onTextChanged?.(e || "")}/>);
}

export default EditRecipe;